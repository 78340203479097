import { combineReducers } from "redux"

function isModalOpen(state = false, action) {
    switch (action.type) {
        case "OPEN MODAL":
            return Object.assign({}, state, {isModalOpen: action.text})
        case "CLOSE MODAL":
            return Object.assign({}, state, {isModalOpen: action.text})
        default:
            return state;
    }
}

function setItem(state = {}, action){
    switch (action.type) {
        case "SET ITEM": 
            return Object.assign({}, state, {selectedItem: action.item})
        default: 
            return state;
    }
}

function wishlist(state = {items: []}, action){
    switch(action.type){
        case "REFRESH REGISTRY": 
            return Object.assign({}, state, {items: action.items})
        default: 
            return state
        }
}

function currentUser(state = {isAuth: false}, action){
  var userObject;
    switch(action.type){
        case "LOGIN": 
            if(action.user.hasOwnProperty("error")) {
                userObject = {
                    currentUser: action.user, 
                    isAuth: false
                }
                return Object.assign({}, state, userObject); 
            }
            else{
                userObject = {
                    currentUser: action.user, 
                    isAuth: true
                }
                return Object.assign({}, state, userObject)
            }
        case "LOGOUT": 
            return Object.assign({}, state, {currentUser: action.user})
        default:
            return state
    }
}

const wishPandaApp = combineReducers({
    isModalOpen, 
    setItem, 
    wishlist, 
    currentUser
})

export default wishPandaApp
