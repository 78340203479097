import React, {Component} from "react"; 
import NavBar from "../NavBar/NavBar";
import twitchSrc from "./../../images/twitch.jpg"; 
import youtubeSrc from "./../../images/youtube.png"; 
import instagramSrc from "./../../images/instagram.png"; 
import { Container, Row, Col, Card, CardTitle, CardBody, CardImg, FormText, ListGroup, ListGroupItem } from "reactstrap";
import "./Brands.css"; 
import CountUp from "react-countup";
const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&"); 
}

class Brands extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            collapse: false,
            email: "", 
            name: "", 
            brand: "", 
            description: "", 
            success: false, 
            error: false
        };
    }
    handleChange = e => this.setState({ [e.target.name]: e.target.value });
    handleSubmit = e => {
        fetch("/brands", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "brands", ...this.state })
        })
        .then(() => {
            this.setState({ success: true }); 
        })
        .catch(error => {
            this.setState({error: true});     
        });
  
        e.preventDefault();
      };

    render(){
        const { name, email, brand, description} = this.state;
        return (
            <div> 
            <NavBar isAuth={this.props.isAuth} profile={this.props.profile}> </NavBar>
            <Col className="extended-header">
            <div className="extended-title"> Wishpanda For Brands</div>
            <div className="extended-subtitle">Our P3 Team has an impressive reach and are ready to work with you</div>
            </Col>
            <Container> 
                <Row>   
                    <Col> 
                        <Card className="brand-card"> 
                        <CardImg top width="300px" src={twitchSrc} alt="Card image cap" />   
                            <CardTitle className="brands-text-center"> <i className="fab fa-twitch"> </i> Twitch Views </CardTitle>
                            <CardBody> 
                                <p className="brands-numbers"> 
                                    <CountUp end={2500513} duration={9}> </CountUp>
                                </p> 
                            </CardBody> 
                        </Card> 
                    </Col> 
                    <Col> 
                        <Card className="brand-card"> 
                            <CardImg top width="300px" src={youtubeSrc} alt="Card image cap" />   

                            <CardTitle className="brands-text-center"> <i className="fab fa-youtube"> </i> YouTube Subscriptions </CardTitle> 
                            <CardBody> 
                                <p className="brands-numbers"> 
                                    <CountUp end={421823} duration={4}>  </CountUp>
                                </p> 
                            </CardBody> 
                        </Card> 
                    </Col> 
                    <Col> 
                        <Card className="brand-card"> 
                            <CardImg top width="300px" src={instagramSrc} alt="Card image cap" />   

                            <CardTitle className="brands-text-center"> <i className="fab fa-instagram"> </i> Instagram Followers </CardTitle> 
                            <CardBody> 
                                <p className="brands-numbers"> 
                                    <CountUp end={250233} duration={4}>  </CountUp>
                                </p> 
                            </CardBody>
                        </Card> 
                    </Col> 
                </Row> 
                <Row> 
                    <Col sm="12" md={{size: 6}}> 
                        <Card className="brand-form-card"> 
                            <CardTitle className="brands-numbers" > Read about some of these success stories with other influencers </CardTitle> 
                            <CardBody>
                                <ListGroup>
                                    <ListGroupItem> 
                                        <a href="https://esportsobserver.com/twitch-shroud-madrinas-coffee/" target="_blank"  rel="noopener noreferrer"> Shroud Sells Out Madrinas Coffee </a> 
                                    </ListGroupItem>
                                    <ListGroupItem> 
                                        <a href="https://esportsobserver.com/red-bull-ninja/" target="_blank"  rel="noopener noreferrer"> RedBull Sponsors Ninja </a> 
                                    </ListGroupItem>
                                    <ListGroupItem> 
                                        <a href="https://twitter.com/AnneMunition/status/671741715116527616" target="_blank"  rel="noopener noreferrer"> Corsair Sponsors Annemunition, named "Top Gamer" by CNBC</a> 
                                    </ListGroupItem>
                                    <ListGroupItem> 
                                        <a href="https://www.relevance.com/how-micro-influencers-are-impacting-traditional-marketing/" target="_blank"  rel="noopener noreferrer"> Micro-Influencers engage their audiences 22x more than traditional influencers</a> 
                                    </ListGroupItem>       
                                </ListGroup> 
                            </CardBody> 
                        </Card> 
                    </Col> 
                    <Col sm="12" md={{size: 6}}> 
                        <Card className="brand-form-card"> 
                            {this.state.error && (<p> Sorry, an error occurred </p>)}
                            {this.state.success && (<p> Your inquiry has been submitted. Please allow 24hrs for us to review. </p>)}
                            {!this.state.success && !this.state.error &&  
                            <form onSubmit={this.handleSubmit}>
                                <FormText> 
                                    <p className="brands-numbers"> Grow your brand with Wishpanda's Premiere P3 Team </p>
                                    <p> $1k min. media budget </p> 
                                    <p> 10% wishpanda service fees </p>  
                                </FormText> 
                                <p>
                                    <label className="max-width" style={{"paddingTop": "20px"}}> 
                                        Your Name: 
                                        <input type="text" name="name" className="brand-input" value={name} onChange={this.handleChange} required/>
                                    </label>   
                                </p>
                                <p>
                                    <label className="max-width">
                                        Your Email: 
                                        <input type="email" name="email" className="brand-input" value={email} onChange={this.handleChange} required/>
                                    </label>
                                </p>
                                <p>
                                    <label className="max-width">
                                        Your Brand's Name: 
                                        <input type="input" name="brand" className="brand-input" value={brand} onChange={this.handleChange} required/>
                                    </label>
                                </p>
                                <p>
                                    <label className="max-width">
                                        Brief Description of Your Product: 
                                        <input type="textarea" name="description" value={description} onChange={this.handleChange}> 
                                        </input> 
                                    </label>
                                </p>
                                <p className="brand-submit">
                                    <button type="submit" className="brand-submit">Submit</button>
                                </p>                                        
                            </form>}
                        </Card> 
                    </Col>
                </Row> 
            </Container> 
            </div>
        )
    }


}

export default Brands