import Endpoints from "./../constants/Api.js";
const request =  require("request-promise");

async function purchaseItem(buyerUserId, item, token) {

  const options = { 
      method: "POST", 
      uri: Endpoints.baseUrlApi +"payments/purchase",
      json: true, 
      body: {
          buyerUserId: buyerUserId, 
          item: item, 
          token: token
      }
  };

  /*
              itemName: req.item.name,
            url: req.item.url, 
            paidPrice: req.item.price,
            buyerMessage: "TODO", 
            buyerUserId: req.body.buyerUserId,
            recipientUserId: "234", 
            recipientUsername: req.body.username

  */

  try {
    const data = await request(options);
    return data;
  } catch (err) {
    console.error(err);
  }
  return undefined;
}


export { purchaseItem };