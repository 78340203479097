import React from 'react';
import Item from './Item/Item';
import './GiftList.css';
import classnames from "classnames";
import { getLeaderboard }  from "../../../api/wishlist"; 
import {
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col, 
  Table
} from "reactstrap";
import * as _ from 'lodash';
import * as moment from 'moment/moment';

class GiftList extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      activeTab: "1",
      popoverOpen: false, 
      ...props
    };
  }

  toggle = (tab) =>  {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  renderPurchasedItems = () => {
    console.log("huh", this.state); 
    var Items = this.props.userlist.filter(function(item){
      if(item && item.status === "PURCHASED"){
        return true;
      }else {
        return false;
      }
    });

    if(Items.length === 0) return (<div> 
      <p> <span role="img" aria-label="crying face">😢</span> {this.props.username} hasn't been gifted yet. You could be their first! </p> 
    </div>)

    Items = Items.map((item, index) => {
      return <Item
      itemInfo={item}
      activeProfile = {this.props.activeProfile}
      editable={this.props.editable}
      username={this.props.username}
      profile={this.props.profile}
      deleteFromLocalWishlist={this.props.deleteFromLocalWishlist}
      renderPaymentModal={!this.props.editable}
      showPurchaseButton={false}
      />
    })
    return Items  
  }
  renderItems = () => {
    var showPurchaseButton = true;
    var showLogin = true;
    if(this.props.profile && this.props.profile.username) { //person is signed in
      showLogin = false;
      if (this.props.profile.username === window.location.pathname.split("/").pop()) { //person looking at own list
        showPurchaseButton = true;
        showLogin = true;
      }
    }
   
    var Items = this.props.userlist.filter(function(item) {
        var shouldDisplayPurchasedItem = false
        if(item && item.purchaseDate){
          if(moment(item.purchaseDate).isBetween(moment().subtract('7', 'days'), moment())){
            shouldDisplayPurchasedItem = true; 
          }
        }
        if(item && (item.status === "ACTIVE" || shouldDisplayPurchasedItem))   {
            return true;
        }
        return false;
    })
    Items = Items.map((item, index) => 
      {
        return <Item
          itemInfo={item}
          activeProfile = {this.props.activeProfile}
          editable={this.props.editable}
          username={this.props.username}
          profile={this.props.profile}
          deleteFromLocalWishlist={this.props.deleteFromLocalWishlist}
          renderPaymentModal={!this.props.editable}
          showPurchaseButton={showPurchaseButton}
          showLogin={showLogin}
          toggleSignInModal={this.props.toggleSignInModal}
      />})
    return Items
  }

  renderLeaderboard = () =>  {
    const leaderboard = {}; 
    

    this.props.userlist.forEach( item => {
      if(item && item.status === "PURCHASED"){
        if(!item.buyerUsername) return; 
        if(!leaderboard[item.buyerUsername]) leaderboard[item.buyerUsername] = 0; 

        leaderboard[item.buyerUsername] += item.price
      };
    });

    //sort the leaderboard object by total price. reverse it. take the top 10. 
    const leaders = Object.keys(leaderboard).sort( (a,b) => leaderboard[a] - leaderboard[b]).reverse().slice(0,9); 

    return (
      <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Username</th>
        </tr>
      </thead>
      <tbody>
        {
          leaders.map( (leader, index) => {
            const link = `/user/${leader}`
            return (
              <tr>
                <th scope="row">
                  {index + 1}
                </th>
                <td> <a href={link}> {leader} </a></td>
              </tr>
            )
          })
        }
      </tbody> 
      </Table>
    

      )
  }

  render() {
    var orderListStyle={
      paddingLeft: "0px"
    }
    return(
      <div>
          <Nav tabs className="Wishlist-Nav-Tabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1"
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Wishlist
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2"
                })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Gift History
              </NavLink>
            </NavItem>
            <NavItem> 
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "3"
                })}
                onClick = {() => {
                  this.toggle("3"); 
                }}
              >
              Leaderboard
              </NavLink>
            </NavItem> 
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                <ol style={orderListStyle}>
                  {this.renderItems()}
                </ol>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="Profile-Address-Row">
                <Col sm="12">
                  {this.renderPurchasedItems()}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="Profile-Address-Row">
                <Col sm="12">
                  {this.renderLeaderboard()}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          
      </div>
    )}
  }

export default GiftList;