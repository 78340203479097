import React, {Component} from "react"; 
import NavBar from "../NavBar/NavBar";
import { Container, Row, Col, Card, CardTitle, CardBody, FormText } from "reactstrap";
import "./P3.css"; 
const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


class P3 extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            collapse: false,
            name: "", 
            email: "", 
            twitch: "", 
            youtube: "", 
            instagram: "", 
            twitter: "", 
            success: false,
            error: false
        };
    }
    
    handleChange = e => this.setState({ [e.target.name]: e.target.value });
    handleSubmit = e => {
        fetch("/p3", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "influencers", ...this.state })
        })
        .then((res) => {
            if(res.status >= 400) throw res; 
            else this.setState({ success: true }); 
        })
        .catch(error => {
            console.log("an error occurred", error); 
            this.setState({error: true});     
            console.log("this.state", this.state); 
        });
  
        e.preventDefault();
      };

    render(){
        const { name, email, twitch, youtube, instagram, twitter } = this.state;
        return (
            <div> 
            <NavBar isAuth={this.props.isAuth} profile={this.props.profile}> </NavBar>
            <Col className="extended-header">
            <div className="extended-title"> Wishpanda For Brands</div>
            <div className="extended-subtitle">Our P3 Team has an impressive reach and are ready to work with you.</div>
            </Col>
            <Container> 
                <Row>   
                    <Col> 
                       <Card className="brand-form-card"> 
                            <CardTitle className="card-padding" > The Panda Partner Program </CardTitle> 
                            <CardBody> 
                                <p> Come join the team! Wishpanda is looking for micro-influencers who can help promote some brands. Through our affiliate network,
                                the P3 team creates engaging content partnered with Brands and affiliate revenue generated can help support their own channels. </p>  

                                <p> Finding sponsorships and partners has never been this easy. Ready to try it out? </p> 
                            </CardBody> 
                       </Card> 
                    </Col> 
                    <Col> 
                        <Card className="brand-form-card"> 
                            {(this.state.error) ? (<p> Sorry, an error occurred, on the previous submission</p>) : (<p> </p>)}
                            {(this.state.success === true) && (<p> Your inquiry has been submitted. Please allow 24hrs for us to review your accounts. </p>)}
                            {!this.state.success === true && 
                            <form onSubmit={this.handleSubmit}>
                                <FormText> 
                                    <p className="brands-numbers"> Apply to Join P3 Here </p> 
                                </FormText> 
                                <p> 
                                <label className="max-width">Your Name: <input type="text" name="name" value={name} onChange={this.handleChange} className="p3-input" required/></label>  
                                </p> 
                                <p>
                                    <label className="max-width">Your Email: <input type="email" name="email" value={email} onChange={this.handleChange} className="p3-input" required/></label>
                                </p>
                                <p>
                                    <label className="max-width">Twitch Username<input type="input" name="twitch" value={twitch} onChange={this.handleChange} className="p3-input" required/></label>
                                </p>
                                <p>
                                    <label className="max-width">YouTube Channel<input type="input" name="youtube" value={youtube} onChange={this.handleChange} className="p3-input" required/></label>
                                </p>
                                <p>
                                    <label className="max-width">Instagram Account<input type="input" name="instagram" value={instagram} onChange={this.handleChange} className="p3-input" required/></label>
                                </p>
                                <p>
                                    <label className="max-width">Twitter Handle<input type="input" name="twitter"  value={twitter} onChange={this.handleChange} className="p3-input" required/></label>
                                </p>
                                <p>
                                    <button type="submit" className="brand-submit">Apply</button>
                                </p>
                            </form>
                            }
                        </Card> 
                    </Col>
                </Row> 
            </Container> 
            </div>
        )
    }
}

export default P3