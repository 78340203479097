import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Col,
  Popover,
  PopoverHeader,
} from "reactstrap";
import classnames from "classnames";
import ReactGA from "react-ga";
import request from "request-promise";
import "./Profile.css";
import Clipboard from "react-clipboard.js";
import  {updateUserInfoById } from "../../api/user"
import pandaLogo from "./../../panda-link-icon.svg";
import bioLogo from "./../../bio-icon.svg";
import streamlabsLogo from "./../../streamlabs-logo-button.png";
import wishpandaBadge from "./../../wishpanda-badge.png";
import { getUserById } from "./../../api/user.js";

class Profile extends React.Component {
  constructor() {
    super();
    
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.handleCommunityIDUpdate = this.handleCommunityIDUpdate.bind(this);
    this.handleProfileBioUpdate = this.handleProfileBioUpdate.bind(this);
    this.handleShippingAddress1Update = this.handleShippingAddress1Update.bind(this);
    this.handleShippingAddress2Update = this.handleShippingAddress2Update.bind(this);
    this.handleShippingCityUpdate = this.handleShippingCityUpdate.bind(this);
    this.handleShippingStateUpdate = this.handleShippingStateUpdate.bind(this);
    this.handleShippingZipUpdate = this.handleShippingZipUpdate.bind(this);
    this.state = {
      activeTab: "1",
      popoverOpen: false
    };
    this.togglePopover = this.togglePopover.bind(this);
  }

  addDefaultSrc = (ev) => {
    ev.target.src = "https://api.adorable.io/avatars/5";
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });

    setTimeout(() => {
      this.setState({
        popoverOpen: !this.state.popoverOpen
      })
    }, 2000)
  }
  componentWillReceiveProps(props) {
    //authed and own profile
    if(props.profile && props.profile.id) {
      getUserById(props.profile.id)
        .then(user => this.setState({user: user}))
        .catch(e => console.log(e));
    }
  }
  componentWillMount = () => {
    var vm = this;
      // not authenticated users profile so fetch data
      const options = {
        method: "get",
        uri: process.env.REACT_APP_API_URL +"users/username/"+this.props.profileToShow,
        json: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-store"
        },
        credentials: "include"
      };
      request(options)
        .then(user => vm.setState({ user: user }))
        .catch(e => console.log(e));

  }
  updateUserInfo() {
    return updateUserInfoById(this.props.profile.id, this.state.user)
      .then((resp) => {
          ReactGA.event({
            category: "User",
            action: "Updated their Info",
            label: this.state.user.username
          });
      })
        .catch(e => console.log(e));
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  handleCommunityIDUpdate(event) {
    var updatedUser = this.state.user
    updatedUser.community_id = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed Community ID",
      label: this.state.user.username
    });
  }
  handleProfileBioUpdate(event) {
    var updatedUser = this.state.user
    updatedUser.profile_bio = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed Community Bio",
      label: this.state.user.username
    });
  }
  handleShippingAddress1Update(event) {
    var updatedUser = this.state.user
    updatedUser.shipping_address_1 = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed Shipping Address 1",
      label: this.state.user.username
    });
  }
  handleShippingAddress2Update(event) {
    var updatedUser = this.state.user
    updatedUser.shipping_address_2 = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed Shipping Address 2",
      label: this.state.user.username
    });
  }
  handleShippingCityUpdate(event) {
    var updatedUser = this.state.user
    updatedUser.shipping_city = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed City",
      label: this.state.user.username
    });
  }
  handleShippingStateUpdate(event) {
    var updatedUser = this.state.user
    updatedUser.shipping_state = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed State",
      label: this.state.user.username
    });
  }
  handleShippingZipUpdate(event) {
    var updatedUser = this.state.user
    updatedUser.shipping_zip = event.target.value
    this.setState({
        user: updatedUser
    })
    ReactGA.event({
      category: "User",
      action: "Changed Zip",
      label: this.state.user.username
    });
  }

  streamlabsControls = () => {
    if(this.state.user.hasAccessToken) {
      return(
        <p className="Profile-PandaLink-Desc">
          You're account is all set to send donation alerts to your Streamlabs account!
        </p>
      )
    } else {
      return (
        <div>
          <p className="Profile-PandaLink-Desc">
          Connect our service to your OBS streaming client. You’ll get an alert whenever
           someone purchases an item from your wishlist!
          </p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.streamlabs.com/api/v1.0/authorize?client_id=Y9szUJy7zdZBHrdZZMFFJ3ayw8JlRGAKR4N6P0sW&redirect_uri=https://api.wishpanda.com:8443/api/auth/streamlabs/callback&response_type=code&scope=alerts.create">
            <input className="Profile-Streamlabs-Link" alt="streamlabs logo" type="image" src={streamlabsLogo} />
          </a>
        </div>
      )
    }
  }

  render() {
    if (this.state.user) {
      if (this.props.editable) {
        return (
          <div>
            
            <Nav tabs className="Profile-Nav-Tabs">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Public Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Shipping Address
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <p className="Profile-PandaLink-Head"> 
                      <img className="logo-panda-link-chain" alt="Panda Link" src={pandaLogo} />
                      &#160;&#160;PANDA LINK & BADGE
                    </p>
                    <p className="Profile-PandaLink-Desc">
                      Use this link and badge online to share your wishlist. (Click to Copy)
                    </p>
                    <Clipboard className="Profile-PandaLink-Box" component="a" button-href="#" data-clipboard-text={"https://www.wishpanda.com/user/" + this.props.profile.username}>
                    <Button id="wishpanda-link" onClick={this.togglePopover}>
                        <span>{"https://www.wishpanda.com/user/" + this.props.profile.username}</span>
                    </Button>
                    </Clipboard>
                    <a download href={wishpandaBadge}>
                    <Button type="submit" className="Profile-badge-button">
                        <span>{"Download Twitch Badge"}&nbsp;<i className="fas fa-arrow-down"></i></span>
                    </Button>
                    </a>
                    <p className="Profile-Streamlab-Button"> 
                      <i className="fas fa-link Profile-fontawesome"/>&nbsp;CONNECT TO STREAMLABS
                    </p>
                    
                    <Popover placement="top" isOpen={this.state.popoverOpen} target="wishpanda-link" toggle={this.togglePopover}>
                      <PopoverHeader>Panda Link copied to clipboard!</PopoverHeader>
                    </Popover>
                    {this.streamlabsControls()}
                    <p className="Profile-Bio-Title">
                      <img className="logo-profile-bio" alt="Bio Icon" src={bioLogo} />
                      &#160;&#160;
                      PROFILE BIO
                    </p>
                    <div className="Profile-Bio-Div">
                    <Input type="textarea" maxLength="400" name="text" id="exampleText" value={this.state.user.profile_bio || ""} onChange={this.handleProfileBioUpdate}/>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row className="Profile-Address-Row">
                  <Col sm="12">
                    <FormGroup>
                      <Label className="Profile-Address-Label" for="exampleAddress">Address</Label>
                      <Input
                        className="Profile-Address-Input"
                        type="text"
                        name="address"
                        id="exampleAddress"
                        placeholder="1234 Main St"
                        value={this.state.user.shipping_address_1}
                        onChange={this.handleShippingAddress1Update}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="Profile-Address-Label" for="exampleAddress2">Address 2</Label>
                      <Input
                        className="Profile-Address-Input"
                        type="text"
                        name="address2"
                        id="exampleAddress2"
                        placeholder="Apartment, studio, or floor"
                        value={this.state.user.shipping_address_2}
                        onChange={this.handleShippingAddress2Update}
                      />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label className="Profile-Address-Label" for="exampleCity">City</Label>
                          <Input
                            className="Profile-Address-Input"
                            type="text" 
                            name="city" 
                            id="exampleCity"
                            value={this.state.user.shipping_city}
                            onChange={this.handleShippingCityUpdate}
                         />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label className="Profile-Address-Label" for="exampleState">State</Label>
                          <Input
                            className="Profile-Address-Input"
                            type="text" 
                            name="state" 
                            id="exampleState"
                            value={this.state.user.shipping_state}
                            onChange={this.handleShippingStateUpdate}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label className="Profile-Address-Label" for="exampleZip">Zip</Label>
                          <Input
                            className="Profile-Address-Input"
                            type="text" 
                            name="zip" 
                            id="exampleZip" 
                            value={this.state.user.shipping_zip}
                            onChange={this.handleShippingZipUpdate}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <button className="saveChangesButton" onClick={() => {
                    this.updateUserInfo();
                  }}>Save Changes</button>
          </div>
        );
      } else {
        var createdDate = new Date(this.state.user.created_at)
        var month = createdDate.toLocaleString("en-us", {month: "long"})
        var day = createdDate.getDate()
        var year = createdDate.getFullYear()
        return (
          
          <div>
            <img 
                className="Profile-Image" 
                alt="user profile" 
                src={this.state.user.imageUrl || "https://api.adorable.io/avatars/5"}
                onError={this.addDefaultSrc}
            />
            <h1 className="Profile-title">
              <strong className="Profile-title-username">
                {this.state.user.username.charAt(0).toUpperCase() +
                  this.state.user.username.slice(1)}
              </strong>
              's Wishlist
            </h1>
            {/* <p className="Profile-Account-Detail">Twitch Streamer</p> */}
            <p className="Profile-Account-Detail">
              <i className="fas fa-calendar-alt calendar"></i>
              &#160;&#160;&#160;
              {"Joined " + month + " " + day + ", " + year}
          </p>
            <i className="fas fa-link panda-link-icon"></i>
              &#160;&#160;
            <a
              href={"https://www.twitch.tv/" + this.state.user.username}
              className="Profile-Account-URL"
            >
              {"https://www.twitch.tv/" + this.state.user.username}
            </a>
            <div className="Profile-Bio-Div-Fan">
              {this.state.user.profile_bio}
            </div>
          </div>
        );
      }
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default Profile;
