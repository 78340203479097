import React from "react";
import NavBar from "./../NavBar/NavBar"
import { UncontrolledCollapse } from "reactstrap";
import { Row, Col } from "reactstrap";
import "./FAQ.css";


class FAQ extends React.Component {

  constructor(props) {
    super(props);
    this.state = { collapse: false };
  }

  render() {
    return(
      <div>
      <NavBar isAuth={this.props.isAuth} profile={this.props.profile}/>
      {/* <Row> */}
          <Col className="extended-header">
            <div className="extended-title"> Questions? Look here.</div>
            <div className="extended-subtitle">Can't find an answer? Contact us at admin@wishPanda.com</div>
          </Col>
      {/* </Row> */}
      <div className="faq-body">
      <ul className="custom-bullet">
          <li>
            <Row>
                <Col>
                  <div id="Q1" className="faq-questions" style={{ marginBottom: "1rem" }}>So what do you guys do exactly?</div>
                  <UncontrolledCollapse toggler="#Q1" isOpen>
                    <span className="faq-answer">
                        We exist to connect influencers, fans, and brands. 

                        We allow people to create a curated wishlist of their favorite items and then share that list with their followers and/or friends &amp; family. Items that are purchased off this wishlist create a streamlabs alert so you know who your real fans are.

                        Wishpanda also has an exclusive team called the P3 (Panda Partner Program). 
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q2" className="faq-questions" style={{ marginBottom: "1rem" }}>Where will items be sent?</div>
                  <UncontrolledCollapse toggler="#Q2">
                    <span className="faq-answer">
                      Items that your order through wishPanda will be sent to the home address on file of the person you choose to send the item to. Conversely, if you’re creating a wishlist any item purchased will be sent to whatever address you put on file.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q3" className="faq-questions" style={{ marginBottom: "1rem" }}>What payment methods do you accept?</div>
                  <UncontrolledCollapse toggler="#Q3">
                    <span className="faq-answer">
                      We currently only accept valid credit cards including Mastercard, Visa, Discovery, and Amex.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q4" className="faq-questions" style={{ marginBottom: "1rem" }}>How many viewers do I need to use your service?</div>
                  <UncontrolledCollapse toggler="#Q4">
                    <span className="faq-answer">
                      There is no cap to how many viewers you need. WishPanda can be by everyone, from professional Twitch streamers to people who just want an easy way to share wishlists online.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q5" className="faq-questions" style={{ marginBottom: "1rem" }}>Is your service free?</div>
                  <UncontrolledCollapse toggler="#Q5">
                    <span className="faq-answer">
                      We offer both a free and paid tier for our service. With the free tier you can only accept 1 item per month from your wishlist. There is no limit to how many gifts you can receive when using the paid tier.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q6" className="faq-questions" style={{ marginBottom: "1rem" }}>How much does your paid tier cost?</div>
                  <UncontrolledCollapse toggler="#Q6">
                    <span className="faq-answer">
                      It costs $9.95 a month to be a wishPanda member.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q7" className="faq-questions" style={{ marginBottom: "1rem" }}>Can I use your service instead of a regular PO Box?</div>
                  <UncontrolledCollapse toggler="#Q7">
                    <span className="faq-answer">
                      Yes! We created this service precisely to allow you not to use a PO Box to receive gifts. With wishPanda you’ll be receiving items straight to your door while ensuring your address privacy from buyers.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q8" className="faq-questions" style={{ marginBottom: "1rem" }}>What if I don’t live in the USA?</div>
                  <UncontrolledCollapse toggler="#Q8">
                    <span className="faq-answer">
                      Sorry! We’re still figuring out how to handle international shipping. For now we only offer our service to people who reside within mainland USA.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q9" className="faq-questions" style={{ marginBottom: "1rem" }}>I like your service. How can I help you?</div>
                  <UncontrolledCollapse toggler="#Q9">
                    <span className="faq-answer">
                      We’re glad we’ve been able to be a positive influence in your life :) If you’d like to help us, please reach out to admin[at]wishpanda.com
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q10" className="faq-questions" style={{ marginBottom: "1rem" }}>How secure is your service?</div>
                  <UncontrolledCollapse toggler="#Q10">
                    <span className="faq-answer">
                      Very secure. We use military grade encryption to protect your address at any given point so no one except you ever knows what the final address where packages arrive.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
          <li>
            <Row>
                <Col>
                  <div id="Q11" className="faq-questions" style={{ marginBottom: "1rem" }}>I can’t find the answer to my question.</div>
                  <UncontrolledCollapse toggler="#Q11">
                    <span className="faq-answer">
                      Sorry to hear that. Please reach out to admin@wishpanda.com and we’ll get back to you within 12h.
                    </span>
                  </UncontrolledCollapse>
                </Col>
            </Row>
          </li>
        </ul>
        </div>
      </div>
    )
  }
}

export default FAQ;
