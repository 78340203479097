export function openModal(text){
    return {
        type: "OPEN MODAL",
        text
    }
}

export function closeModal(text){
    return {
        type: "CLOSE MODAL", 
        text
    }
}

export  function setItem(item){
    return {
        type: "SET ITEM", 
        item
    }
}

export function refreshWishlist(items){
    return {
        type: "REFRESH REGISTRY", 
        items
    }
}

export function userLogin(user){
    return {
        type: "LOGIN", 
        user
    }
}