import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import logo from "./../../wishpanda-logo.svg";
import "./Footer.css";


class Footer extends Component {
    render() {
        return (
            <div>
              <footer className='footer' header='Footer'>
                <Container>
                    <Row>
                        <Col className="footer-padding">
                        <h1 className="footer-headers">
                            <img className="logo-image-footer" alt="Wishpanda Logo" src={logo} />
                        </h1>
                        </Col>
                        <Col className="footer-padding">
                        <h1 className='footer-headers'>WISHPANDA</h1>
                            <ul className='footer-links'>
                                <li><a href="/brands" className="panda-link"> For Brands </a></li> 
                                <li><a href="/p3" className="panda-link" >For Influencers</a></li> 
                                <li><a href="/FAQ" className='panda-link'>FAQs</a></li>
                            </ul>
                        <h1 className='all-rights'>© 2019. All Rights Reserved.</h1>
                        </Col>
                        <Col className="footer-padding">
                            <h1 className="footer-headers"> CONTACT</h1> 
                            <ul className="footer-links"> 
                                <li><a href="https://discord.gg/g9d75Ey" className='panda-link'> <span> <i className="fab fa-discord"> </i> </span> Discord Channel</a></li>
                                <li><a href="admin@wishpanda.com" className='panda-link'>admin@wishpanda.com</a></li>
                            </ul>
                        </Col> 
                        <Col className="footer-padding">
                        <h1 className='footer-headers'>SOCIAL</h1>
                            <ul className='footer-links'>
                                {/* <li><a className='panda-link' href=''>Facebook</a></li> */}
                                <li><a className='panda-link' href='https://twitter.com/wishpanda'><span> <i className="fab fa-twitter"> </i> </span> Twitter</a></li>
                                <li><a className='panda-link' href='https://www.instagram.com/_wishpanda/'><span> <i className="fab fa-instagram"></i> </span>  Instagram </a></li>
                                {/* <li><a className='panda-link' href=''>Pinterest</a></li>  */}
                            </ul>
                        </Col>
                    </Row>
                </Container>
              </footer>
            </div>
        )
      }
    }
    
export default Footer