// index.js
import React, {Component} from "react";
import {
	CardElement,
  StripeProvider,
  Elements,
  injectStripe
} from "react-stripe-elements";
import { Row, Container} from "reactstrap"
import "./Payment.css"; 
import { purchaseItem } from "../../../../../api/pay.js"
import { closeModal, refreshWishlist } from "../../../../../actions/actions";
import { getWishlistByUserId } from "../../../../../api/wishlist.js"; 


// const contextType = PaymentModalContext

const handleBlur = () => {
  console.log("[blur]");
};
const handleChange = (change) => {
  console.log("[change]", change);
};
const handleFocus = () => {
  console.log("[focus]");
};
const handleReady = () => {
  console.log("[ready]");
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        width: "500px", 
        fontFamily: "Roboto, sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class _CardForm extends Component {
  constructor(props){
    super(props); 

    this.state = {
      disabled: false
    }
  }

  handleClose = (ev) => {
    ev.preventDefault();
    this.props.dispatch(closeModal(false)); 
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.props.stripe) {
      this.setState({disabled: true}); 
      var buyerUserId = (this.props.currentUser.isAuth) ? this.props.currentUser.currentUser.id : null
      this.props.stripe
        .createToken()
        .then((payload) => purchaseItem(buyerUserId, this.props.item.selectedItem.itemInfo, payload.token.id) )
        .then((successfulPay) => { 
          this.setState({disabled: false}); 
          this.props.dispatch(closeModal(false)); 
          return getWishlistByUserId(window.localStorage.getItem("userId"))
        })
        .then( (wishlist) => this.props.dispatch(refreshWishlist(wishlist)) )
        .catch((e) => console.log("an error", e));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  render() {
    return (
      <div>
      <Container> 
        <form>
        <Row> 
          <label>
            Card Details
            <CardElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
            />
          </label>
        </Row>
        <Row >
          <div class="center-purchase-buttons">
            <button id="cancel-purchase" className="cancel-purchase-modal" onClick={this.handleClose}>Cancel</button> 
            <button id="purchase" className="buy-purchase-modal" onClick={this.handleSubmit} disabled={this.state.disabled}>Purchase</button>
          </div>
        </Row>
        </form>
      </Container>
      </div>
    );
  }
}
const CardForm = injectStripe(_CardForm);

class Checkout extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      elementFontSize: window.innerWidth < 450 ? "14px" : "18px",
    };
    window.addEventListener("resize", () => {
      if (window.innerWidth < 450 && this.state.elementFontSize !== "14px") {
        this.setState({elementFontSize: "14px"});
      } else if (
        window.innerWidth >= 450 &&
        this.state.elementFontSize !== "18px"
      ) {
        this.setState({elementFontSize: "18px"});
      }
    });
  }

  render() {
    const {elementFontSize} = this.state;
    return (
      <div className="Checkout">
        <Elements>
          <CardForm fontSize={elementFontSize} {...this.props} />
        </Elements>
      </div>
    );
  }
}

class Payment extends Component{
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Checkout {...this.props}/>
      </StripeProvider>
    )}
};

export default Payment; 