import React, { Component } from "react";
import { Button, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import ReactGA from "react-ga";
import GiftList from "./GiftList/GiftList.js";
import "./GiftRegistry.css";
import { getWishlistByUserId, updateItem } from "./../../api/wishlist";
import { getUserByUsername } from "./../../api/user";
import { connect } from "react-redux";
import { refreshWishlist } from "../../actions/actions";
import noItemImage from "./../../no-item-icon.svg";
import { getLeaderboard } from "../../api/wishlist";
const leaderboardAmount = 5;

class GiftRegistry extends Component {
  constructor(props) {
    super(props);


    this.state = {
      wishlist: [],
      searching: false,
      itemUrl: "",
      itemFound: null,
      itemMessage: "",
      itemSearchFailed: false,
      username: (this.props.profile && this.props.profile.username) ? this.props.profile.username : "",
      user: {},
      leaderboard: {}
    };
  }
  

  componentDidMount() {
    this.fetchWishlist();
  }

  fetchWishlist = () => {
    var userFromUrl = window.location.pathname.split("/").pop();
    return getUserByUsername(userFromUrl)
      .then(user => {
        window.localStorage.setItem("userId", user.id);
        this.setState({
          user: user,
          username: user.username
        });
        getWishlistByUserId(user.id).then(wishlist => {
          this.setState({ searching: false });
          this.props.dispatch(refreshWishlist(wishlist));
        });
        getLeaderboard(user.id, leaderboardAmount)
          .then(result => this.setState({ leaderboard: result }))
          .catch(err => {
            throw err
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteFromLocalWishlist = itemId => {
    var copyWishlist = this.props.wishlist.items;
    copyWishlist.forEach(function (item, index) {
      if (item.id === itemId) {
        item.status = "DELETED";
        copyWishlist.splice(index, 1);
      }
    });
    this.props.dispatch(refreshWishlist(copyWishlist));
  };
  updateInputValue = evt => {
    this.setState({
      itemUrl: evt.target.value
    });
  };

  addToWishlist = () => {
    var updatedItemFound = this.state.itemFound;

    updatedItemFound.price = (this.state.itemFound.price * 1.15 + .3).toFixed(2);
    updatedItemFound.note = this.state.itemMessage;
    updatedItemFound.status = "ACTIVE";

    this.setState({
      itemFound: updatedItemFound,
      itemMessage: ""
    });

    updateItem(updatedItemFound).then(data => {
      ReactGA.event({
        category: "User",
        action: "Added Product to Wishlist",
        label: this.props.profile.username
      });
      var newWishlist = this.props.wishlist.items;
      newWishlist.unshift(data);
      this.setState({
        searching: false,
        itemUrl: "",
        itemFound: null
      });
      this.props.dispatch(refreshWishlist(newWishlist));
    });
  };
  confirmEnterWasPressed = e => {
    if (e.key === "Enter") {
      this.confirmAddItem();
    }
  }
  confirmAddItem = e => {
    var vm = this;
    this.setState({
      searching: true,
      itemSearchFailed: false
    });
    fetch(
      process.env.REACT_APP_API_URL +
      "users/" +
      this.props.profile.id +
      "/wishlist",
      {
        method: "POST",
        body: JSON.stringify({ amazonUrl: this.state.itemUrl }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-store"
        },
        credentials: "include"
      }
    )
      .then(resp => resp.json())
      .then(data => {
        console.log(data);
        if (!data.name || data.price === "unknown") {
          //  failed to find item
          ReactGA.event({
            category: "User",
            action: "Failed to Search for Product: " + this.state.itemUrl,
            label: this.props.profile.username
          });
          vm.setState({
            searching: false,
            itemFound: null,
            itemUrl: null,
            itemSearchFailed: true
          });
        } else {
          ReactGA.event({
            category: "User",
            action: "Searched for Product",
            label: this.props.profile.username
          });
          vm.setState({
            searching: false,
            itemFound: data
          });
        }
      })
      .catch(e => console.log(e));
  };
  cancelAddingItem = () => {
    this.setState({
      itemFound: null,
      itemUrl: ""
    });
    ReactGA.event({
      category: "User",
      action: "Canceled Adding Item to Wishlist",
      label: this.props.profile.username
    });
  };
  showItemFail = () => {
    if (this.state.itemSearchFailed) {
      return (
        <p className="Wishlist-addInstructions">
          <span role="img" aria-label="Grinning Face With Sweat">😅</span> Looks like we couldn't find that product. Please try another.
        </p>
      )
    }
  }
  addItem = () => {
    if (this.state.searching) {
      return (
        <div className="GiftRegistry-Loading">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
          <p>Loading item... This might take a sec...</p>
          <Button outline color="secondry">
            Close
          </Button>{" "}
        </div>
      );
    } else if (this.state.itemFound != null) {
      var itemImage;
      if (this.state.itemFound.imageUrl === -1) {
        itemImage = noItemImage;
      } else {
        itemImage = this.state.itemFound.imageUrl;
      }
      return (
        <div className="GiftRegistry-AddItemBox">
          <img alt="itemImage" height={61} width="59" src={itemImage} />{" "}
          <span>{this.state.itemFound.name}</span>
          <br />
          <span>${(this.state.itemFound.price * 1.15 + .3).toFixed(2)}</span>
          <input
            value={this.state.itemMessage}
            className="Wishlist-messageInput"
            placeholder="You can add a public personal message to this item (Optional)"
            type="text"
            onChange={evt => this.setState({ itemMessage: evt.target.value })}
          />
          <div style={{ margin: "10px" }}>
            <Button outline color="secondary" onClick={this.cancelAddingItem}>
              Cancel
          </Button>
            {' '}
            <Button outline color="primary" onClick={this.addToWishlist}>
              Add Item to Wishlist
          </Button>
          </div>
        </div>
      );
    } else {
      if (this.props.editable) {
        return (
          <div className="GiftRegistry-addItem">
            <InputGroup>
              {this.showItemFail()}
              <p className="Wishlist-addInstructions">
                Paste an item URL from Amazon to add it to your wishlist
            </p>
              <input
                value={this.state.itemUrl}
                className="Wishlist-itemInput"
                placeholder="Enter Amazon item URL here"
                type="text"
                onKeyPress={this.confirmEnterWasPressed}
                onChange={evt => this.updateInputValue(evt)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="button-div">
                  <Button className="confirm-add-button" onClick={this.confirmAddItem} color="secondary">
                    Confirm
                  </Button>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>

          </div>
        );
      } else {
        return null;
      }
    }
  };
  render() {
    if (this.state.loading) {
      return (
        <div>
          {this.addItem()}
          <p>Loading...</p>
        </div>
      );
    } else {
      if (this.props.wishlist.items === 0) {
        if (this.props.editable) {
          return (
            <div>
              {this.addItem()}
              <span
                className="GiftRegistry-no-items"
                role="img"
                aria-label="no items added"
              >
                Aww, you haven't added any items to your wishlist yet 😿. Paste
                an Amazon product URL to begin adding stuff 🎉
              </span>
            </div>
          );
        } else {
          return (
            <div>
              {this.addItem()}
              <span className="GiftRegistry-no-items-guest">
                Aww, {this.state.user.username} hasn't added any items to their
                wishlist yet 😿
              </span>
            </div>
          );
        }
      } else {
        return (
          <div>
            {this.addItem()}
            <GiftList
              activeProfile={this.state.user}
              userlist={this.props.wishlist.items}
              editable={this.props.editable}
              username={this.state.username}
              profile={this.props.profile}
              deleteFromLocalWishlist={this.deleteFromLocalWishlist}
              toggleSignInModal={this.props.toggleSignInModal}
            />
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return { wishlist: state.wishlist };
};

GiftRegistry = connect(mapStateToProps)(GiftRegistry);
export default GiftRegistry;
