import Endpoints from "../constants/Api";
const request = require("request-promise");
const headers = require("../constants/globals").headers;

async function getWishlistByUserId(userId) {
  const options = {
    method: "GET",
    uri: process.env.REACT_APP_API_URL + "users/" + userId + "/wishlist",
    json: true
  };

  try {
    const data = await request(options);
    return data;
  } catch (err) {
    console.error(err);
  }
  return undefined;
}

async function updateItem(item) {
  console.log('inside wishlist api frontend', item); 

  const options = {
    method: "PUT", 
    headers: headers, 
    uri: Endpoints.baseUrlApi + "items/", 
    json: true,
    body: item 
  }; 

  try {
    const response = await request(options); 
    console.log(response); 
    return response;
  }
  catch (err){
    console.log("there was an error updating the item", err); 
  }
}

async function getLeaderboard(userId, topAmount) {
  console.log('activeProfile', userId);
  const options = {
    method: "GET",
    headers: headers,
    uri: Endpoints.baseUrlApi + "users/" + userId + "/leaderboard",
    qs: { limit: topAmount },
    json: true
  };

  try {
    const response = await request(options);
    return response;
  } catch (err) {
    console.error(err);
  }
  return {}
}

export { getWishlistByUserId, updateItem, getLeaderboard };
