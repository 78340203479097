import React from "react";
import {
    Col,
    Card,
    Container } from "reactstrap";

class FormSuccess extends React.Component {
    render() {

        return (
            <Container>
             <Col sm="12" md={{ size: 6, offset: 3 }}>
             <Card>Your application has been received. The Wishpanda Team will be in touch shortly.</Card>
             </Col> 
            </Container> 
            );
    }
}

export default FormSuccess;
