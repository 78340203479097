const defaultHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-store"
}; 

function getUserByUsername(username) {
  return fetch(process.env.REACT_APP_API_URL + "users/username/" + username, {
      method: "GET",
      headers: defaultHeaders,
      credentials: "include"
  }).then((resp) => resp.json());
}

function updateUserInfoById(id, userObj){
  return fetch(process.env.REACT_APP_API_URL +"users/"+id, {
    method: "PUT",
    headers: defaultHeaders,
    credentials: "include",
    body: JSON.stringify(userObj)
  });
}

function getUserById(id){
  return fetch(process.env.REACT_APP_API_URL +"users/"+id, {
    method: "GET",
    headers: defaultHeaders,
    credentials: "include"
  }).then((resp) => resp.json());
}

function fetchAllUsers(limit, offset){
  //default limit is 12 at a time
  var theLimit = (!limit) ? 12 : limit;
  var theOffset =(!offset)? 1 : offset;  
  return fetch(process.env.REACT_APP_API_URL + "users/getAllUsers?limit="+theLimit+"&offset="+ theOffset, {
    method: "GET", 
    headers: defaultHeaders,
    credentials: "include"
  })
  .then((resp) => resp.json())
  .catch(((e) => console.log("ERROR", e) ));  
}

export { getUserByUsername, updateUserInfoById, getUserById, fetchAllUsers };