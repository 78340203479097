import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Truncate from "react-truncate";
import user from "./../../../../placeholder-user.jpg";
import noItemImage from "./../../../../no-item-icon.svg";
import "./Item.css";
import PaymentModal from "./PaymentModal/PaymentModal.js";
import { connect } from "react-redux";
import { openModal, closeModal, setItem } from "../../../../actions/actions";
import ReactGA from "react-ga";

class Item extends Component {
  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.clickedShowPay = this.clickedShowPay.bind(this);
    this.changeBackdrop = this.changeBackdrop.bind(this);
    this.deleteItemFromWishlist = this.deleteItemFromWishlist.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.tooltipToggle = this.tooltipToggle.bind(this); 
    this.state = {
      renderPaymentModal: this.props.renderPaymentModal, 
      item: this.props,
      showBuyDiv: false,
      showPaymentForm: false,
      backdrop: true,
      modalIsOpen: false,
      width: 0,
      height: 0, 
      currentUserWishlist: this.props.editable, 
      tooltipOpen: false
    };
  }

  tooltipToggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onMouseEnter() {
    if (!this.state.currentUserWishlist) {
      this.setState({ showBuyDiv: true });
    }
  }

  onMouseLeave() {
    if (!this.state.currentUserWishlist) {
      this.setState({ showBuyDiv: false });
      this.hidePaymentForm();
    }
  }

  clickedShowPay() {
    this.setState({ showPaymentForm: true });
  }

  hidePaymentForm() {
    this.setState({ showPaymentForm: false });
  }

  changeBackdrop(e) {
    let value = e.target.value;
    if (value !== "static") {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value });
  }

  handleOpenModal = () => {
    console.log(this.props.showLogin)
    if(this.props.showLogin) {
      this.props.toggleSignInModal("in");
    } else {
      this.props.dispatch(openModal(true));
      this.props.dispatch(setItem(this.state.item));
    }
  };

  handleCloseModal = () => {
    this.props.dispatch(closeModal(false));
  };

  displayPaymentModal = () => {
    if(this.state.renderPaymentModal){
      return(
        <PaymentModal 
          itemInfo={this.props.itemInfo}
          activeProfile = {this.props.activeProfile}
          editable={false}
          username={this.props.username}
          profile={this.props.profile}
          deleteFromLocalWishlist={this.props.deleteFromLocalWishlist}> 
        </PaymentModal>
      )
    }
  }

  addDefaultSrc = (ev) => {
    ev.target.src = noItemImage;
  }

  deleteItemFromWishlist = item => {
    fetch(
      process.env.REACT_APP_API_URL +
        "users/" +
        this.props.profile.id +
        "/items/" +
        item.id,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        },
        credentials: "include"
      }
    )
      .then(() => {
        ReactGA.event({
          category: "User",
          action: "Deleted an Item"
        });
        this.props.deleteFromLocalWishlist(item.id);
      })
      .catch(e => console.log(e));
  };

  showPurchaseButton = () => {
    if (this.props.showPurchaseButton) {
      return (
        <button
          className="show-purchase-modal-button"
          onClick={this.handleOpenModal}
        >
          <i className="fas fa-shopping-cart" /> Gift Item
        </button>
      );
    }
  };
  showItemName = () => {
    return (
      <div className="item-name">
      <Truncate  lines={2} ellipsis={"..."}>
        {this.props.itemInfo.name}
      </Truncate>
      </div>
    )
  }
  itemNote = () => {
    if (this.props.itemInfo.note) {
      return (
        <div className="item-quote">
          
          <div className="tooltip-div">
            <span className="bubble">{this.props.itemInfo.note}</span>
          </div>
          <div className="img-container"> 
            <img 
            className="item-user"
            alt="user"
            src={this.props.activeProfile.imageUrl || "https://api.adorable.io/avatars/285/" + this.props.profile.username}
            height={20}
            width={20}
            />
          </div>
        </div>
      );
    }
  };

  itemControls = () => {
    if(this.state.currentUserWishlist) {
      return (
        <i
          className="fas fa-trash-alt trash-icon"
          onClick={() => this.deleteItemFromWishlist(this.props.itemInfo)}
        />
      )
    }
  }
  itemDisplay = () => {
    var productImage;
    if (this.props.itemInfo.imageUrl === -1) {
      productImage = noItemImage;
    } else {
      productImage = this.props.itemInfo.imageUrl;
    }
    if (this.props.itemInfo.purchased) {
      var buyerTag = <span className="purchased-item-buyer-text-anonymous">
      {this.props.itemInfo.buyerUsername || "Anonymous"}{" "}
    </span>
      if (this.props.itemInfo.buyerUsername) {
        buyerTag = <a href={this.props.itemInfo.buyerUsername}>
        <span className="purchased-item-buyer-text">
          {this.props.itemInfo.buyerUsername || "Anonymous"}{" "}
        </span>
        </a>
      }
      return (
        <li className="item-li-purchased">
          <Row>
            <Col sm={{ size: 4, offset: 4 }}>
              <div className="purchased-item-pic-container">
                <img
                  alt="itemImage"
                  className="purchased-item-pic"
                  src={productImage}
                  onError={this.addDefaultSrc}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 8, offset: 2 }}>
              <div className="purchased-item-name">
                <p> {this.props.itemInfo.name.substring(0, 50) + "..."} </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 8, offset: 2 }}>
              <div style={{ fontSize: "10px", textAlign: "center" }}>
                {" "}
                was gifted by{" "}
              </div>
              <div className="purchased-item-buyer-username">
                <img
                  alt="buyerImage"
                  className="purchased-item-buyer-image"
                  src={this.props.itemInfo.buyerImg || user}
                />
                {buyerTag}
              </div>
            </Col>
          </Row>
        </li>
      );
    } else {
      var dollars;
      var cents;
      if (this.props.itemInfo.price) {
        var splitPriceString = Number.parseFloat(this.props.itemInfo.price)
                                     .toFixed(2)
                                     .toString()
                                     .split(".");
        dollars = splitPriceString[0];
        cents = splitPriceString[1];
      }
      return (
        <li className="item-li">
          {this.displayPaymentModal()}
          {/* <Container> */}
          <Row style={{ height: "100%" }}>
            <Col className="Item-pic-col" xs="3">
              <img
                className="Item-pic"
                alt={this.props.itemInfo.name}
                src={productImage}
                onError={this.addDefaultSrc}
              />
              {/* </div> */}
            </Col>
            <Col xs="8" style={{ height: "100%" }}>
              <div>
                {this.showItemName()}              
              </div>
              <div className="item-price-row">
                <p className="item-price">
                  <span className="dollar-sign">$</span>
                  <span className="item-price-dollars">{dollars}</span>
                  <span className="item-price-cents">{cents}</span>
                </p>
                {this.showPurchaseButton()}
              </div>
              {this.itemNote()}
            </Col>
            {this.itemControls()}
          </Row>
        </li>
      );
    }
  };

  render() {
    return this.itemDisplay();
  }
}

export default connect()(Item);
