const globals = {
    activeItem: {},
    showModal: false, 
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-store"
    }
}

export default globals
