import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import App from "./App";
import Landing from "./components/Landing/Landing";
import Auth from "./Auth";
import Callback from "./Callback";
import FAQ from "./components/FAQ/FAQ";
import History from "./History";
import Footer from "./components/Footer/Footer"
import Explore from "./components/Explore/Explore";
import Brands from "./components/Brands/Brands"; 
import FormSuccess from "./components/FormSuccess/FormSuccess";
import P3 from "./components/P3/P3"; 
import { connect } from "react-redux";
import { userLogin } from "./actions/actions"

const auth = new Auth();
const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

class CreateRoutes extends Component {
  constructor(props) {
      super(props);
      this.state = {
          isAuth: false,
          profile: null
      }
  }
  componentWillMount() {
    var vm = this;
    fetch( process.env.REACT_APP_API_URL + "auth/user/data",{
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-cache"
        },
        credentials: "include"
    })
    .then((resp) => resp.json())
    .then((resp) => {
      if(resp.username) {
        this.setState({
          isAuth: true,
          profile: resp,
          ReactGA: ReactGA
        })
      } else {
        this.setState({
          // isAuth: false,
          profile: null,
          ReactGA: ReactGA
        })
      }
      this.props.dispatch(userLogin(resp))
    })   
    .catch( e => console.log("this is a problem", e) )
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <div>
        <Router history={History}>
          <Route
            render={({ location }) => (
              <div className="App">
                <div className="Router">
                    <Switch location={location}>
                      <Route path="/callback" render={(props) => {
                        handleAuthentication(props);
                        return <Callback {...props} /> 
                      }}/>

                      <Route path="/explore" render={(props) => {
                        handleAuthentication(props); 
                        return <Explore {...props} isAuth={this.state.isAuth} profile={this.state.profile} />
                      }} />

                      <Route path="/success" render={() => { 
                        return <FormSuccess> </FormSuccess> 
                      }}/> 

                      <Route path="/faq" render={(props) => {
                        ReactGA.pageview("/faq");
                        return(<FAQ isAuth={this.state.isAuth} profile={this.state.profile} {...props} />)}
                      }/>

                      <Route path="/p3" render={(props) => {
                        ReactGA.pageview("/p3"); 
                        return (<P3 isAuth={this.state.isAuth} profile={this.state.profile} {...props} />)}
                      }/> 

                      <Route path="/brands" render={(props) => {
                        ReactGA.pageview("/brands"); 
                        return (<Brands isAuth={this.state.isAuth} profile={this.state.profile} {...props} />)}
                      }/> 
                        
                      <Route path={"/user/mixer/:id"}  render={(props) =>
                        <App isAuth={this.state.isAuth} profile={this.state.profile}  {...props} />} />

                      <Route path={"/user/:id"}  render={(props) =>
                        <App isAuth={this.state.isAuth} profile={this.state.profile}  {...props} />} />

                      <Route path="/" render={(props) => 
                        <Landing isAuth={this.state.isAuth} profile={this.state.profile} {...props} />} />
                      <Route render={() => <div>Not Found</div>} />
                    </Switch>
                </div>
              </div>
              )}
            />
          </Router> 
          <Footer/>
        </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
           currentUser: state.currentUser
         }
}

CreateRoutes = connect(mapStateToProps)(CreateRoutes);
export default CreateRoutes;
