import React, { Component } from "react";
import Payment from "../Payment/Payment.js"
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Item from "../../Item/Item";
import { closeModal } from "../../../../../actions/actions";
import  "./PaymentModal.css"; 

class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemName: "",
      username: "",
      price: "", 
      item: {}
    }
  };
  
  componentWillReceiveProps(newProps){
    if(newProps.item){
      if(newProps.item.selectedItem){
        this.setState({
          item: newProps.item.selectedItem.itemInfo,
          itemName: newProps.item.selectedItem.itemInfo.name,
          username: newProps.item.selectedItem.username,
          price: Math.round(newProps.item.selectedItem.itemInfo.price * 1.05).toFixed(2)
        })
      }
    }
  }

  redirectToSignIn(){
    window.localStorage.setItem("__WP__CALLBACK_URL", window.location.href); 
    window.location.href= process.env.REACT_APP_API_URL + "auth/twitch/"; 
  }

  checkIfGifterIsSignedIn(){
    if(this.props.currentUser.isAuth){
      return (<Payment {...this.props}> </Payment>)
    }
    else{
      return (<div> 
                <h5> Oops. Looks like you're not signed in. Sign in to continue.</h5>  
                  <button class="sign-in-button" onClick={this.redirectToSignIn}> Sign In </button>
              </div> )
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(closeModal(false)); 
  }

  render() {
    return (
      <Modal isOpen={this.props.isModalOpen.isModalOpen} >
            <ModalHeader> 
              <p class="modal-header-title"> Purchasing A Gift For</p>
              <p class="modal-header-title"> <img class="modal-profile-picture-rounded" alt="profile" src={this.props.activeProfile.imageUrl} /> {this.state.username} </p> 
              <div class="close-modal">   
                <i class="fa fa-times-circle" aria-hidden="true" onClick={this.handleCloseModal}> </i> 
              </div>
            </ModalHeader>
            <ModalBody>
              <Item 
                        itemInfo={this.state.item}
                        activeProfile = {this.props.activeProfile}
                        editable={this.props.editable}
                        showPurchaseButton={false}
                        username={this.props.username}
                        profile={this.props.profile}
                        renderPaymentModal={false}
                        deleteFromLocalWishlist={this.props.deleteFromLocalWishlist}> 
              </Item> 
              {this.checkIfGifterIsSignedIn()}
            </ModalBody>    
      </Modal>
    );
  }
}



const mapStateToProps = (state) => {
   return {
            isModalOpen: state.isModalOpen, 
            item: state.setItem,
            currentUser: state.currentUser
          }
}

PaymentModal = connect(mapStateToProps)(PaymentModal);
export default PaymentModal;
