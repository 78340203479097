import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import "./index.css";
import CreateRoutes from "./CreateRoutes";
import registerServiceWorker from "./registerServiceWorker";
import { CookiesProvider } from "react-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { createStore } from "redux"; 
import wishPandaApp from "./reducers/reducers"; 
import { Provider } from "react-redux"; 
const store = createStore(wishPandaApp); 

const unsubscribe = store.subscribe(() => console.log(store.getState()))
unsubscribe();


console.log(process.env.REACT_APP_ANALYTICS);
ReactGA.initialize(process.env.REACT_APP_ANALYTICS);

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <CreateRoutes />
        </Provider>
    </CookiesProvider>, document.getElementById("root")
)
registerServiceWorker();
