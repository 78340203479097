import styled from "styled-components"; 
import * as palette from "../../constants/colors"; 
export const Wrapper = styled.div`

.center-pagination{
    justify-content: center;
    margin-top: 30px;
}

.page-item .page-link{
    color: ${palette.wishpandaPurple}
}

.page-item.active .page-link{
    background-color: ${palette.wishpandaPurple}; 
    color: ${palette.white}; 
    border-color: #eee; 
}

.user-card{
    height: 80px; 
    box-shadow: 0 26px 30px -18px rgba(0,0,0,.3);
    border-radius: 4px; 
    margin: 10px;
    padding: 18px 0px 20px 25px; 
    cursor: pointer; 
    background: #fff; 
}

.user-card:hover{
    border:1px solid #fff; 
    box-shadow: 0 26px 30px -18px rgba(0,0,0,.3);
}

.user-card-picture{
    border-radius: 50%; 
    border: 2px solid #fff; 
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    display: inline-block; 
}
.all-user-cards{
    margin-top: -50px; 
}
.angle-panda{
    color: #e9e3f4;
}
.inline-block{
    display: inline-block;
}

.user-card-username{
    margin-top: 8px;
    color: #955aff;
    display: inline-block;
    font-weight: 600;
}

.user-card-text-block{
    padding-left: 15px; 
    font-family: Roboto, sans-serif; 
    text-decoration: underline; 
}

.wishlist-font{
    font-weight: 400; 
    font-family: Roboto, sans-serif; 
    color: #955aff;
    text-decoration: none; 
}

@media (max-width:480px) {
   .all-user-cards{
    margin-top: 0px; 
   } 
}
`

