import React from "react";
import Navbar from "./../NavBar/NavBar";
import {
    Alert,
    Row,
    Col } from "reactstrap";
import headerImage from "./../../header2x.png";
import "./Landing.css";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      showAlert: false,
      signInModalOpen: false,
      upOrIn: "up"
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    if(this.props.location.search === "?e=Account%20already%20claimed"){
      this.setState({showAlert: true})
    }
  }
  toggleSignInModal = (text) => {
    this.setState({
      signInModalOpen: !this.state.signInModalOpen,
      upOrIn: text
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  signIn = () => {
    window.location.href = process.env.REACT_APP_API_URL + "auth/twitch/"
  }

  showAlert = () => {
    if(this.state.showAlert){
      return (
        <Alert color="danger">
          Account already claimed
        </Alert>
      )
    }
  }
  render() {
    return (
      <div className="Landing">
      {this.showAlert()}
        <Navbar isAuth={this.props.isAuth} profile={this.props.profile} signInModalOpen={this.state.signInModalOpen} toggleSignInModal={this.toggleSignInModal} upOrIn={this.state.upOrIn}/>
        <div  className="Landing-subtitle">
            <p>
                Wishpanda is shutting down :(
            </p>
            <p>
                This site will be deactivated by 11/28/19.
            </p>
            <p className="subtext">Get in-stream purchase notifications and securely receive gifts from your fans — no more PO Box needed!</p>
            <button className="Landing-signup-cta" onClick={this.signIn}>
                  Sign Up
            </button>
        </div>
        <div className="Landing-header">
            <img className="Landing-header-image" alt="preview-of-wishlist" src={headerImage}/>
        </div>

        </div>
    );
  }
}

export default Landing;
