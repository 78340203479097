import React, { Component } from "react"
import NavBar from "./components/NavBar/NavBar"
import Profile from "./components/Profile/Profile"
import GiftRegistry from "./components/GiftRegistry/GiftRegistry"
import { Container, Row, Col } from "reactstrap";
import "./App.css"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInModalOpen: false,
      upOrIn: "up"
    }
  }
  componentDidMount(){
    var url = window.localStorage.getItem("__WP__CALLBACK_URL"); 
    if(url){
      window.localStorage.setItem("__WP__CALLBACK_URL", ""); 
      window.location.href= url; 
    }
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      isAuth: newProps.isAuth,
      profile: newProps.profile,
    })
  }
  render() {
      if(this.state.profile) {
        var editable = this.props.profile.username === this.props.match.params.id ? true : false
        return (
            <div className="App">
                <NavBar isAuth={this.props.isAuth} profile={this.props.profile}/>        
                <Container>  
                <Row> 
                    <Col sm="6">
                        <div className="App-profile">
                        <Profile isAuth={this.props.isAuth} profile={this.props.profile} profileToShow={this.props.match.params.id} editable={editable} fake={true}/> 
                        </div>
                    </Col> 
                    <Col sm="6"> 
                        <div className="App-wishlist">
                        <GiftRegistry profile={this.props.profile} profileToShow={this.props.match.params.id} toggleSignInModal={this.toggleSignInModal} editable={editable}/>
                        </div>
                    </Col>
                </Row> 
                </Container>

            </div>
        )
      } else {
        return ( 
            <div className="App">
                <NavBar isAuth={false} profile={this.props.profile}/>
                <Container> 
                <Row> 
                    <Col sm="6">
                        <div className="App-profile">
                        <Profile isAuth={this.props.isAuth} profileToShow={this.props.match.params.id} editable={false}/> 
                        </div>
                    </Col> 
                    <Col sm="6"> 
                        <div className="App-wishlist">
                        <GiftRegistry profile={this.props.profile} profileToShow={this.props.match.params.id} editable={false} toggleSignInModal={this.toggleSignInModal} />
                        </div>
                    </Col>
                </Row> 
                </Container>
            </div>
        )
      }
  }
}

export default App;
