import React, { Component } from "react";
import { Container, Row, Pagination, PaginationItem, PaginationLink, Col } from "reactstrap";
import logo from "./../../wishpanda-logo.svg";
import NavBar from "../NavBar/NavBar";
import {fetchAllUsers} from "../../api/user.js"; 
import {Link} from "react-router-dom"; 
import { Wrapper } from "./style"; 

const numberOfUsersPerPage = 12; 

class Explore extends Component {

    constructor(props) {
        super(props);
   
        this.state = {
            totalPages: 0,
            limit: 100, 
            offset: 1, 
            users: [], 
            activePage: 1
        };
    }

    componentWillMount(){
        fetchAllUsers(this.state.limit, this.state.offset)
            .then((usersArray) => {
                // var filtered = usersArray.filter((user) => {if(user.total >= 1) return user}) 
                this.setState({users: usersArray}); 
                this.setState({totalPages: Math.ceil(usersArray.length/numberOfUsersPerPage)}); 
            }); 
    }

    activePage = (pageNumber) => {
        this.setState({activePage: pageNumber})
    }

    createPages(){
        var lis = [];
        for (let i=1; i <= this.state.totalPages; i++) { // use let here to avoid closure
          lis.push(
            <PaginationItem
              key={i}
              active={
                this.state.activePage === i ? true : false
              }
            >
              <PaginationLink href="javascript:void(0)" onClick={() => this.activePage(i)} >
                {i}
              </PaginationLink>
            </PaginationItem>
          );
        }
        return lis; 
    }

    addDefaultSrc = (ev) => {
        ev.target.src = "https://api.adorable.io/avatars/5";
    }

    render() {
        var usersStart = (this.state.activePage === 1) ? 0 : (this.state.activePage-1) * numberOfUsersPerPage; 
        var users = this.state.users.slice(usersStart, usersStart + numberOfUsersPerPage); 
        users.forEach(function(user) {
          if(user.provider === "mixer") {
            user.dashboardLink = "/user/mixer/" + user.username;
          } else {
            user.dashboardLink = "/user/" + user.username;
          }
        });
        return (
            <Wrapper>
            <NavBar isAuth={this.props.isAuth} profile={this.props.profile}> 
            </NavBar>
            <div className="all-user-cards"> 
                <Container>
                    <Row>
                    {users.map( (user) => (
                        <div className="col-sm-6" key={user.id}>
                            <Link to={user.dashboardLink}>  
                                <div className="user-card"> 
                                    <div className="inline-block"> 
                                        <img className="user-card-picture" 
                                            src={user.imageUrl || "https://api.adorable.io/avatars/285/" + user.username} 
                                            onError={this.addDefaultSrc}
                                            alt="User avatar" height="36px" width="36px" /> 
                                    </div>
                                    <div className="user-card-username"> 
                                        <span className="user-card-text-block"> {user.username}'s </span><span className="wishlist-font"> wishlist </span>  <i className="fa fa-angle-right 2x angle-panda"> </i>
                                    </div>
                                </div> 
                            </Link>
                        </div> 
                    ))}
                    </Row> 
                    <Row className="center-pagination">
                        <Pagination size="sm" aria-label="Pagination Bar">
                            {this.createPages()}
                        </Pagination>
                    </Row>

                </Container>
            </div>
            </Wrapper>
            )
        }
    }
export default Explore