import {Component} from "react";
// import request from "request-promise"

class Auth extends Component {

  constructor(props) {
    super(props);
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.state = {
        profile: null
    };
  }

  getProfile() {
    return this.state.profile
  }

  isAuthenticated() {
    console.log("in isAuthenticated", this)
    // Check whether the current time is past the
    // Access Token's expiry time
    return fetch( process.env.REACT_APP_API_URL + "auth/user/data",{
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-cache"
        },
        credentials: "include"
        })
        // .then(function(profile) {
        //     console.log(profile)
        //     vm.setState({
        //         profile: profile
        //     })
        // })
  }
}

export default Auth