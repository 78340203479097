import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import logo from "./../../wishpanda-logo.svg";
import user from "./../../placeholder-user.jpg";
import twitchAuthLogo from "./../../twitch-auth-logo.svg";
import mixerAuthLogo from "./../../mixer-auth-logo.svg";
import "./NavBar.css";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      profileImage: user,
      isAuth: false,
      isModalOpen: false,
      upOrIn: "in"
    };
  }

  toggle = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  addDefaultSrc = (ev) => {
    ev.target.src = "https://api.adorable.io/avatars/5";
  }

  render() {
    if (this.props.isAuth) {
      return (
        <div id="panda-nav">
          <Navbar light expand="md">
            <NavbarBrand tag={Link} to="/">
              <img className="logo-image" alt="Wishpanda Logo" src={logo} />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} to="/faq">FAQ</NavLink>
                </NavItem>
                <NavItem> 
                  <NavLink tag={Link} to="/explore">Explore</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link}to={"/user/" + this.props.profile.username}>Dashboard</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={process.env.REACT_APP_API_URL + "auth/logout"}>Logout</NavLink>
                </NavItem>
                <NavItem>
                    <img
                      className="nav-profile-image"
                      src={this.props.profile.imageUrl || "https://api.adorable.io/avatars/285/" + this.props.profile.username}
                      onError={this.addDefaultSrc}
                      alt="ProfilePicture"
                    />
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
            <p>{this.state.user}</p>
          </div>
        )
    } else {
        return(
        <div id="panda-nav">
          <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggle()} className="auth-modal">
            <ModalHeader>Great! How would you like to sign in? 😲 </ModalHeader>
            <ModalBody>
              <Button className="twitch-auth-button" href={process.env.REACT_APP_API_URL + "auth/twitch"}><img className="twitch-auth-logo" alt="twitch logo" src={twitchAuthLogo}/>Sign {this.state.upOrIn} with Twitch</Button>
              <Button className="mixer-auth-button" href={process.env.REACT_APP_API_URL + "auth/mixer"}><img className="mixer-auth-logo" alt="mixer logo" src={mixerAuthLogo}/>Sign {this.state.upOrIn} with Mixer</Button>
            </ModalBody>
            <ModalFooter>
              <span className="cancel-auth-button" color="secondary" onClick={this.toggle}>Cancel</span>
            </ModalFooter>
          </Modal>
        <Navbar color="" light expand="md">
            <NavbarBrand tag={Link} to="/">
            <img className="logo-image" alt="Wishpanda Logo" src={logo}/>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
                <NavItem> 
                  <NavLink tag={Link} to="/faq">FAQ</NavLink>
                </NavItem>
                <NavItem> 
                  <NavLink tag={Link} to="/explore">Explore</NavLink>
                </NavItem> 
                <NavItem>
                  <NavLink onClick={() => this.toggle()}>Sign In</NavLink>                
                </NavItem>
            </Nav>  
            </Collapse>
        </Navbar>
        </div>
        )
    }
    }
}

Navbar.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  fixed: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  // pass in custom element to use
};
export default NavBar;
